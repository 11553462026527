import "../App.css";
import "./style.css";
import { NavLink } from "react-router-dom";
import { Icon } from "@iconify/react";
import QuoteCards from "./QuoteCards";
import "aos/dist/aos.css";
import CheckOutPlatform from "./CheckOutPlatform";
import "./RounderPercentage.css";
import {
  CircularProgressbar,
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { easeQuadInOut } from "d3-ease";
import AnimatedProgressProvider from "./AnimatedProgressProvider";
import { useState, useEffect, useRef, useCallback } from "react";
import { useInView } from "react-intersection-observer";

export default function HomeInfoPage() {
  const data = [
    {
      percentage: 82,
      desc: `82% of Lenders Rate our customer service as "Well Above Average"`,
      icon: (
        <Icon
          icon="pepicons-pop:thumbs-up-circle"
          className="icon"
          style={{ color: "white" }}
        />
      ),
      color: "A",
    },
    {
      percentage: 92,
      desc: `92% of Lenders say the platform makes communication with their borrowers Easier with 22% saying it's Signficantly Easier`,
      icon: (
        <Icon
          icon="bx:conversation"
          className="icon"
          style={{ color: "white" }}
        />
      ),
      color: "B",
    },
    {
      percentage: 52,
      desc: `52% of Lenders say the site makes it easier for them to recover Late Loans`,
      icon: (
        <Icon
          icon="tabler:calendar-clock"
          className="icon"
          style={{ color: "white" }}
        />
      ),
      color: "B",
    },
    {
      percentage: 90,
      desc: `90% of Lenders say their Team finds the platform easier to use than other platforms`,
      icon: (
        <Icon icon="mdi:tab-find" className="icon" style={{ color: "white" }} />
      ),
      color: "A",
    },
    {
      percentage: 93,
      desc: `93% of Lenders have referred the platform to a friend`,
      icon: (
        <Icon icon="mdi:users" className="icon" style={{ color: "white" }} />
      ),
      color: "A",
    },
    {
      percentage: 82,
      desc: `82% of Lenders say that the platform offers "Incredible Value"`,
      icon: (
        <Icon
          icon="fluent:checkmark-starburst-24-regular"
          className="icon"
          style={{ color: "white" }}
        />
      ),
      color: "b",
    },
  ];

  const { ref, inView, entry } = useInView({
    triggerOnce: false, // Animation triggers only once
  });

  return (
    <div
      style={{
        backgroundImage: `url("./images/12141.jpg")`,
        backgroundSize: "cover",
        color: "#fff",
        padding: "3vh",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundAttachment: "fixed",
        boxShadow: "0px 0px 0px 20000px rgba(255, 255, 255, 0.45) inset", // Corrected the boxShadow property
      }}
    >
      <div className="container my-5 ">
        <h1
          className="text-success titleFontSize primary-green text-center pb-5 my-3"
          style={{ fontSize: "6vh" }}
        >
          Facing the Facts
        </h1>

        <div
          className="row justify-content-evenly"
          ref={ref}
          id="animatedProgressActiveDiv"
        >
          {data?.map((row, index) => (
            <div className="col-12 col-md-9 col-lg-6 col-xl-5 g-5">
              <div className="box g-5">
                <span className="box1 bg-white">
                  <h3
                    className="card-title"
                    style={{
                      backgroundColor:
                        row?.color === "A" ? `rgb(0, 142, 0)` : "rgb(6 91 246)",
                    }}
                  >
                    {row?.icon}
                  </h3>
                </span>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="desc-class w-75 text-black">{row?.desc}</div>
                  {inView ? (
                    <div style={{ width: "35%", margin: "0 auto" }}>
                      <AnimatedProgressProvider
                        valueStart={0}
                        valueEnd={row?.percentage}
                        duration={1.4}
                        easingFunction={easeQuadInOut}
                        repeat={inView}
                      >
                        {(value) => {
                          const roundedValue = Math.round(value);
                          return (
                            <CircularProgressbarWithChildren
                              value={roundedValue}
                              styles={buildStyles({
                                strokeLinecap: "butt",
                                pathColor:
                                  row?.color === "A"
                                    ? `rgb(0, 142, 0)`
                                    : "rgb(6 91 246)",
                                textColor:
                                  row?.color === "A"
                                    ? "rgb(0, 142, 0)"
                                    : "rgb(6 91 246)",
                                trailColor:
                                  row?.color === "A"
                                    ? "rgba(84, 214, 44, 0.16)"
                                    : "#065bf629",
                                // backgroundColor: "red",
                              })}
                              strokeWidth={11}
                              // text={`${row?.percentage}%`}
                            >
                              <div style={{ width: "63%", margin: "0 auto" }}>
                                <CircularProgressbar
                                  value={roundedValue}
                                  styles={buildStyles({
                                    strokeLinecap: "butt",
                                    pathColor: "#c7c2c27d",
                                    textColor:
                                      row?.color === "A"
                                        ? "rgb(0, 142, 0)"
                                        : "rgb(6 91 246)",
                                    trailColor: "#c7c2c27d",
                                    textSize: "30px",
                                  })}
                                  strokeWidth={2}
                                  text={`${roundedValue}%`}
                                />
                              </div>
                            </CircularProgressbarWithChildren>
                          );
                        }}
                      </AnimatedProgressProvider>
                    </div>
                  ) : (
                    <div style={{ width: "35%", margin: "0 auto" }}>
                      <CircularProgressbarWithChildren
                        value={row?.percentage}
                        styles={buildStyles({
                          strokeLinecap: "butt",
                          pathColor:
                            row?.color === "A"
                              ? `rgb(0, 142, 0)`
                              : "rgb(6 91 246)",
                          textColor:
                            row?.color === "A"
                              ? "rgb(0, 142, 0)"
                              : "rgb(6 91 246)",
                          trailColor:
                            row?.color === "A"
                              ? "rgba(84, 214, 44, 0.16)"
                              : "#065bf629",
                          // backgroundColor: "red",
                        })}
                        strokeWidth={11}
                        // text={`${row?.percentage}%`}
                      >
                        <div style={{ width: "63%", margin: "0 auto" }}>
                          <CircularProgressbar
                            value={row?.percentage}
                            styles={buildStyles({
                              strokeLinecap: "butt",
                              pathColor: "#c7c2c27d",
                              textColor:
                                row?.color === "A"
                                  ? "rgb(0, 142, 0)"
                                  : "rgb(6 91 246)",
                              trailColor: "#c7c2c27d",
                              textSize: "30px",
                            })}
                            strokeWidth={2}
                            text={`${row?.percentage}%`}
                          />
                        </div>
                      </CircularProgressbarWithChildren>
                    </div>
                  )}
                </div>
                <div className="vertical-line pt-2" />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
